.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}
body{
  background-color: #f7f7f7;
  /* background-color: transparent; */

}
.login_content{
  min-width:300px;
}

.login_content form input[type="number"]{
  margin: 0 0 20px;

}

select{
  margin: 0 0 20px;
}
/* .login_content form div.row div.col-6 {
  padding-left: 10px;
  padding-right: 10px;
} */


/* .login form {
  color:white;
}

.login form p a{
  color:white;
}

.login form div a{
  color:white;
} */

/* .adminSideNav{
  max-width: 20vh;
  background-color:#DB3D44;
  background-color:#00bcd4;
  max-width: 30vh;
  min-height: 100vh;
  padding-left:10px;
  padding-top:10px;
  z-index: 10;
} */

/* .adminSideNav a{
  text-decoration: none;
} */

/* .adminHeader{
background-color: #00bcd4;
max-height: 20vh;

} */
/* 
.uk-position-relative {
  position: relative !important ;
} */
/* .nav_menu ul li a{
  padding-right: 70px;
} */
/* .adminContainer{
  min-height: 100vh;
} */
/* .main_menu{
  min-height: 100vh;
} */


.sideMenuTop{
  width: 100%;
  text-align: center;
}

.login_container{
  width: 95%;
  margin: 40px;
  text-align: center !important;
}

.map_container{
  margin-top: -40px;
  width: 500px;
  float: left;
}

.mapStyle{
  width: 80px;
  height: 500px;
}

.login_leftcontent{
  width: 60%;
  margin: auto;
}

.registration_submit{
  width: 100% !important;
}

.link{
  cursor:pointer;
  color: #2c87f0;
  
}

.middleModal{
  top: 10%;
  left: 40%;
  width: 50%;
  height:25%;
}

.left_col.scroll-view{
  height: 100%;
  position: fixed;
}

canvas {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.viewSubRow{
  background-color: #f3f3f3;
  margin: 10px;
}

.img_wrp {
  display: inline-block;
  position: relative;
}
.close {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.floatLeft{
  float: left;
}

.noHover{
  pointer-events: none;
}

.SelectSeparator{
      vertical-align: center;
      height: 10px;
}

.SelectHeader {
  color: #282c34;
  font-size: 25px;
}

.PreSchoolMultipleLabel{
  font-size: 21px;
}

.ListLoader{
  text-align: center;
  padding-top: 150px;
  padding-bottom: 200px;
}


.marquee {
  top: 0em;
  position: relative;
  box-sizing: border-box;
  animation: marquee 6s linear infinite;
}

.marquee:hover {
  animation-play-state: paused;
}

/* Make it move! */
@keyframes marquee {
  0%   { top:   8em }
  100% { top: -11em }
}

/* Make it look pretty */
.microsoft .marquee {
margin: 0;
  padding: 0 1em;
  line-height: 1.5em;
  font: 1em 'Segoe UI', Tahoma, Helvetica, Sans-Serif;
  height: 490px !important;
}

.microsoft:before, .microsoft::before,
.microsoft:after,  .microsoft::after {
  left: 0;
  z-index: 1;
  content: '';
  position: absolute;
  pointer-events: none;
  width: 100%; height: 2em;
  background-image: linear-gradient(top, #FFF, rgba(255,255,255,0));
}

.microsoft:after, .microsoft::after {
  bottom: 0;
  transform: rotate(180deg);
}

.microsoft:before, .microsoft::before {
  top: 0;
}

/* Style the links */
.vanity {
  color: #333;
  text-align: center;
  font: .75em 'Segoe UI', Tahoma, Helvetica, Sans-Serif;
}

.vanity a, .microsoft a {
  color: #1570A6;
  transition: color .5s;
  text-decoration: none;
}

.vanity a:hover, .microsoft a:hover {
  color: #F65314;
}

/* Style toggle button */
.toggle {
display: block;
  margin: 2em auto;
}

.mainHeaderTitle{
  color: #D9DEE4;
  font-size: 18px;
  text-align: center;
}

.notificationPanel{
  height: 500px;
  overflow: auto;
}

.notificationPanel::-webkit-scrollbar {
  width: 5px;
  background-color: white;
}

.notificationPanel::-webkit-scrollbar-track
 {
     -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
     background-color: #F5F5F5;
 }

 .notificationPanel::-webkit-scrollbar-thumb
 {
     background-color: rgb(141, 141, 141);
 }

 .notificationPanel::-webkit-scrollbar-thumb:hover
 {
     background-color: #009CE7;
 }

 /* Setting Reports Stat Styles start */

.settingReportStatMainDiv {
  margin-bottom: 2rem;
}

div.ui.grid.settingReportStatGrid {
  margin: 0 auto;
}

div.column.settingReportStatCol {
  padding-left: 5px !important;
  padding-right: 5px !important;
  /* margin: 0 auto; */
}

.settingReportStatTable {
  height: 100%;
  width: 100%;
}

.settingReportStatCard {
  padding: 1.3rem 1rem;
  border-radius: 5px;
  height: 100%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.settingReportStatCardRow1 {
   color: white;
   font-size: 1.2rem;
   font-weight: 400;
   padding-bottom: 0.6rem;
  text-align: right;
   line-height: 22px;

}

.settingReportStatCardRow2 {
  color: white;
  font-size: 2.4rem;
  font-weight: bold;
  text-align: right;
  padding-top: 0.6rem;
}

.centeredDiv {
  margin: 0 auto;
  width: fit-content;
}

.settingReportStatCardRow3 {
  color: white;
  font-size: 1.4rem;
  font-weight: 400;
  padding-right: 1.5rem;
 text-align: right;
  line-height: 22px;

}

.settingReportStatCardRow4 {
 color: white;
 font-size: 3rem;
 font-weight: bold;
 text-align: center;
}

.settingReportStatCardRow5 {
  color: white;
  font-size: 1.2rem;
  font-weight: 400;
  padding-top: 0.6rem;
 text-align: center;

}

.settingReportStatCardRow6 {
 color: white;
 font-size: 2.4rem;
 font-weight: bold;
 text-align: center;
 padding-bottom: 0.6rem;
}

.settingReportStatSummaryCard {
  padding: 1.5rem 1rem;
  border-radius: 5px;
  height: 100%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

td.rightBorderedTd {
  border-right: 1px solid rgba(255, 255, 255, 0.575);

}

div.column.settingReportSummaryStatCol {
  width: 50%;
}

 /* Setting Reports Stat Styles end */

 .recentEventsRow {
   padding-top: 0px !important;
 }